// pm sidebar
.sidebar {
  // background-color: $blue;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  .nav-link {
    .nav-icon {
      color: $white;
    }
    &.active {
      background-color: darken($color: $blue, $amount: 8%);
      .nav-icon {
        color: $white;
      }
    }
    &:hover {
      background-color: darken($color: $blue, $amount: 8%);
    }
  }
}