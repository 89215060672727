.dropdown {
  .dropdown-menu {
    background-color: $white;
    border: none;
    border-radius: 0;
    box-shadow: 0 6px 10px 2px rgba(0,0,0,0.15);
    min-width: 250px;
  }
  .filter-dropdown-menu {
    min-width: auto;
  }
}

.filter-dropdown-item{
  padding: 5px 20px !important;
  color: #284f81 !important;

}