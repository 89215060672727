@each $color, $hex in $colors {
  .bg-#{$color} {
    background-color: $hex !important;
  }

  .text-#{$color} {
    color: $hex !important;
  }

  hr {
    &.#{$color} {
      border-top-color: $hex !important;
    }
  }

  .#{$color}-border {
    border: 1px solid $hex;
  }
  .#{$color}-border-left {
    border-left: 1px solid $hex;
  }
  .#{$color}-border-top {
    border-top: 1px solid $hex;
  }
  .#{$color}-border-right {
    border-right: 1px solid $hex;
  }
  .#{$color}-border-bottom {
    border-bottom: 1px solid $hex;
  }

  .callout-#{$color} {
    border-left-color: $hex;
  }
  
  @media #{$small-up} {
    .bg-sm-#{$color} {
      background-color: $hex !important;
    }
    .text-sm-#{$color} {
      color: $hex !important;
    }
    .border-sm-#{$color} {
      border-color: $hex !important;
    }
  }
  @media #{$medium-up} {
    .bg-md-#{$color} {
      background-color: $hex !important;
    }
    .text-md-#{$color} {
      color: $hex !important;
    }
    .border-md-#{$color} {
      border-color: $hex !important;
    }
  }
  @media #{$large-up} {
    .bg-lg-#{$color} {
      background-color: $hex !important;
    }
    .text-lg-#{$color} {
      color: $hex !important;
    }
    .border-lg-#{$color} {
      border-color: $hex !important;
    }
  }
  @media #{$xlarge-up} {
    .bg-xl-#{$color} {
      background-color: $hex !important;
    }
    .text-xl-#{$color} {
      color: $hex !important;
    }
    .border-xl-#{$color} {
      border-color: $hex !important;
    }
  }
}

body {
  background-color: $white;
}