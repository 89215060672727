.ag-theme-balham {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  .ag-root {
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-color: darken($color: $grey, $amount: 10%);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .ag-root-wrapper-body {
    &.ag-layout-normal {
      box-shadow: 0 6px 10px -1px rgba(0,0,0,0.15);;
    }
  }
  .ag-header {
    background-color: $green;
    color: $white;
    font-size: 14px;
  }
  .ag-row-selected {
    background-color: $grey;
    border-color: darken($color: $grey, $amount: 10%);
    &.ag-row-hover {
      background-color: $grey;
    }
  }
  .ag-row-hover {
    background-color: lighten($color: $grey, $amount: 5%);
  }
  .ag-cell {
    cursor: pointer;
  }
  .ag-ltr {
    .ag-cell-focus {
      border: none;
    }
  }
  .ag-status-bar {
    border: none;
  }
  .ag-column-select-panel {
    .ag-column-tool-panel-column {
      .ag-column-drag {
        margin-right: 4px;
      }
    }
  }
  .ag-primary-cols-filter {
    padding-left: 4px;
    padding-right: 4px;
  }
  .ag-primary-cols-header-panel {
    height: 40px;
    padding-top: 0;
    a {
      padding-top: 10px;
    }
  }
  .ag-side-bar {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: $grey;
    .ag-side-buttons {
      .ag-selected {
        button {
          background-color: $grey;
        }
      }
    }
  }
  &.blue {
    .ag-header {
      background-color: $blue;
    }
  }
  &.grey {
    .ag-header {
      background-color: $grey;
      color: $darkgrey
    }
  }
}

/* icon rehash */
.ag-theme-balham {
  .ag-icon-menu {
    background-image: url(assets/img/table-icons/icon-menu.svg);
  }
  .ag-icon-checkbox-checked:empty {
    background-imagE: url(assets/img/table-icons/icon-checkbox-checked.svg);
  }
  .ag-icon-checkbox-indeterminate {
    background-image: url(assets/img/table-icons/icon-checkbox-indeterminate.svg);
  }
  .ag-icon-asc {
    background-image: url(assets/img/table-icons/icon-asc.svg);
  }
  .ag-icon-desc {
    background-image: url(assets/img/table-icons/icon-desc.svg);
  }
}


.react-bs-table-container {
  &.blue {
    .react-bs-table {
      th {
        background-color: $blue;
        color: $white;
      }
    }
  }
}

.react-bs-table-tool-bar {
  margin-bottom: 24px !important;
}
.react-bs-table {
  &.react-bs-table-bordered {
    border: none;
    box-shadow: $box-shadow;
    border-radius: 4px;
  }
  .fa-sort, .fa-sort-desc, .fa-sort-asc {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: $white !important;
  }
  .table {
    th {
      padding: 0.25rem 0.75rem;
      background-color: $green;
      color: $white;
      &:first-child {
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
    }
  }
}

.table-responsive {
  border-radius: 4px;
  box-shadow: $box-shadow;
  table {
    &.table {
      border: none;
      border-radius: 4px;
      margin-bottom: 0;
      th {
        padding: 0.25rem 0.75rem;
        background-color: $green;
        color: $white;
        border-top: none;
        &:first-child {
          border-top-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
        }
      }
      td {
        padding: 0.25rem 0.75rem;
      }
    }
  }
}