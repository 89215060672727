// slick carousel
.slick-track {
  margin-left: 0;
  margin-right: 0;
}

.slick-slide {
  padding: 0 8px;
}

.slick-next {
  right: 25px;
  &:before {
    content: "\e079";
    font-family: simple-line-icons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    color: $black;
  }
}

.slick-prev {
  left: 25px;
  &:before {
    content: "\e07a";
    font-family: simple-line-icons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    color: $black;
  }
}

.slick-arrow {
  z-index:100;
  width: 40px;
  height: 40px;
  background-color: rgba(255,255,255,0.7);
  border-radius: 20px;
  box-shadow: 0 6px 10px -4px rgba(0,0,0,0.15);
  &:hover, &:focus, &:active {
    background-color: rgba(255,255,255,1);
  }
}

// bootstrap carousel
.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: rgba(255,255,255,0.7);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 10px -4px rgba(0,0,0,0.15);
  background-image: none;
  position: relative;
  font-size: 20px;
  padding: 10px 0;
  cursor: pointer;
  &:before {
    font-family: simple-line-icons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    color: $black;
    vertical-align: top;
  }
  &:hover, &:focus, &:active {
    background-color: rgba(255,255,255,1);
  }
}

.carousel-control-prev-icon {
  &:before {
    content: "\e07a";
  }
}

.carousel-control-next-icon {
  &:before {
    content: "\e079";
  }
}