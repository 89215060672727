.parent {
  .bg-logo {
    background-color: rgba(168, 205, 122, 0.58);
  }
  img {
    transition: transform 0.2s;
    &:hover {
      z-index: 1000;
      transform: scale(1.2);
    }
  }
}

.vertical-align {
  margin-top: auto;
  margin-bottom: auto;
}
