.errorBoundary-header {
  margin: 10px 0;
  text-align: center;
}

.errorBoundary-wrap {
  background-color: #304F7D !important;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: calc(100vh - 74px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h4 {
    color: var(--green);
    text-transform: uppercase;
  }
}
