.card {
  &.no-border {
    border: none;
  }
  &.blue {
    border-radius: 0;
    background-color: $blue;
    color: $white;
    border: none;
    .card-header, .card-body, .card-footer {
      background-color: $blue;
      color: $white;
      border: none;
      border-radius: 0;
    }
    .card-header {
      padding-bottom: 0;
    }
  }
  &.green {
    border-radius: 0;
    background-color: $green;
    color: $white;
    border: none;
    .card-header, .card-body, .card-footer {
      background-color: $green;
      color: $white;
      border: none;
      border-radius: 0;
    }
    .card-header {
      padding-bottom: 0;
    }
  }
}

.admin, .renter-admin {
  .card {
    border: none;
    box-shadow: $box-shadow;
    .card-header {
      background-color: $green;
      color: $white;
      font-weight: 500;
      // border: 1px solid darken($color: $green, $amount: 4%);
      // border-bottom: none;
      border: none;
      .cutout {
        display: inline-block;
        padding: 10px;
        // margin-top: -30px;
        margin: -30px 15px 13px 0px;
        background-color: darken($color: $green, $amount: 15%);
        box-shadow: $box-shadow;
        border-radius: 4px;
      }
      &.bg-blue {
        background-color: $blue;
        .cutout {
          background-color: darken($color: $blue, $amount: 15%);
        }
      }
      &.bg-grey {
        background-color: $grey;
        .cutout {
          background-color: darken($color: $grey, $amount: 15%);
        }
      }
      &.bg-darkgrey {
        background-color: $darkgrey;
        .cutout {
          background-color: darken($color: $darkgrey, $amount: 15%);
        }
      }
    }
    .card-body {
      // border: 1px solid darken($color: $grey, $amount: 8%);
      // border-top: none;
      border: none;
    }
  }
}

.card {
  &.card-widget {
    flex-direction: column;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    border: none;
    border-radius: 0.25rem;
    .card-body {
      padding: 0;
      display: flex;
      align-items: center;
      i {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        color: $white;
      }
    }
  }
}