.listing-banner {
  position: relative !important;
  .container {
    &>.row {
      @media #{$medium-up} {
        min-height:150px;
      }
    }
  }
}

.listing-nav {
  .nav-item {
    .nav-link {
      color: $darkgrey;
      font-weight: 700;
      text-transform: uppercase;
      padding: 15px 20px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 8px;
        bottom: 0;
        left: 0;
        background-color: $green;
        transform: scale(0,1);
        transform-origin: right;
        transition: transform 250ms ease-out;
      }
      &:hover {
        &:after {
          transform: scale(1,1);
          transform-origin: left;
        }
      }
    }
    &.favorite {
      .nav-link {
        color: $pink;
        &:after {
          width: 0;
          height: 0;
          background: none;
          transform: none;
        }
        &:hover {
          &:after {
            transform: none;
          }
        }
      }
    }
  }
}

.listing-images {
  max-height: 200px;
  @media #{$small-up} {
    max-height: 405px;
  }
  @media #{$medium-up} {
    max-height:540px;
  }
  @media #{$large-up} {
    max-height: 480px;
  }
  @media #{$xlarge-up} {
    max-height: 570px;
  }
}