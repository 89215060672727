.form-group {
  &.row {
    align-items: center;
    label {
      margin-bottom: 0;
    }
  }
}

.form-control {
  border-radius: 2px;
  color: $black;
  &.form-control-pill {
    border-radius: 50em;
  }
}

select {
  &.form-control {
    &.is-valid, &.is-invalid {
      background-position: center right calc(2.0625rem / 4), center right calc(1.2rem);
    }
  }
}

.input-group {
  .input-group-prepend {
    .input-group-text {
      border-radius: 0;
    }
  }
  input {
    border-radius: 0;
  }
  .input-group-append {
    .input-group-text {
      border-radius: 0;
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    &:before {
      top: 0.11rem;
      border-radius: 2px;
    }
    &:after {
      top: 0.11rem;
    }
  }
}

.input-group {
  .react-datepicker-wrapper {
    flex: 1 0 0;
  }
}
.react-datepicker-wrapper {
  display: block !important;
  max-width: 300px;
  .react-datepicker__input-container {
    display: block !important;
  }
}

.DateInput_input {
  &.DateInput_input__small {
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.5;
    font-weight: 400;
    font-family: $font-family-sans-serif;
    text-align: center;
  }
}

.list-group-item {
  .custom-select {
    border: none;
    padding: 0;
    border-radius: 0;
    height: auto;
  }
}