.how-it-works {
  margin-top: 20px !important;
  h1 {
    margin-bottom: 0px !important;
  }
  nav {
    .breadcrumb {
      padding: 0px;
      border: none;
      background-color: transparent;
      .breadcrumb-item.active {
        font-weight: normal !important;
      }
      .breadcrumb-item {
        font-size: 16px;
      }
      .breadcrumb-item a {
        font-weight: 600 !important;
      }
    }
  }
  .rest-desc {
    font-size: 19px;
    max-width: 80%;
    margin: 0px auto;
  }
  .rest-desc span {
    font-weight: 600;
  }
  .page-subtitle {
    margin: 40px auto 20px;
    width: 300px;
    h2 {
      font-weight: 600;
      border-bottom: 3px solid #999999;
      padding: 7px 10px;
      font-size: 32px;
      text-align: center;
    }
  }
  .btn-groups {
    width: 370px;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    .btns {
      width: 180px;
      height: 42px;
      border-radius: 21px;
      text-align: center;
      display: flex;
      border: 2px solid #284f81;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      text-decoration: none !important;
      background-color: transparent;
      color: #284f81;

      &:focus {
        outline: none;
      }

      &:hover {
        color: #162b47;
      }

      &.active {
        background-color: #284f81;
        color: #ffffff;
        margin-right: 10px;
      }
    }
  }
  .flex-rows {
    display: flex;
    .flex-col {
      flex: 1 1;
    }
  }
  .hiw-flow-grid {
    .grid-box {
      margin-top: 30px;
      img {
        display: block;
        width: 90px;
        margin: 0px auto 20px;
      }
      padding: 0px 15px;
      h4 {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      }
      p {
        text-align: center;
        font-size: 16px;
      }
    }
  }
  .features {
    margin-top: 40px;
    h2 {
      font-size: 36px;
    }
    ul {
      margin-top: 20px;
      padding-left: 25px;
      li {
        h4 {
          font-weight: 600;
          margin-bottom: 0px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .videoContainer {
    margin-top: 15px;
    .btn-contact-us {
      width: 100%;
      border: none;
      border-radius: none;
      height: 60px;
      font-size: 18px;
      margin-top: 20px;
      background-color: #284f81;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      img {
        margin-right: 10px;
      }
    }
  }
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 599px) {
  .how-it-works .hiw-flow-grid .grid-box {
    width: 100% !important;
  }
  .hiw-flow-grid.flex-rows {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .hiw-flow-grid .flex-col {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .how-it-works .hiw-flow-grid .grid-box {
    width: 100% !important;
  }
  .hiw-flow-grid.flex-rows {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .hiw-flow-grid .flex-col {
    width: 50%;
    flex: inherit !important;
  }
  .hiw-flow-grid .flex-col:last-child {
    width: 100%;
  }
  .grid-box {
    max-width: 250px;
    margin: 0px auto;
  }
}
@media only screen and (max-width: 992px) {
  .features .flex-rows {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .features .flex-col {
    width: 100%;
  }
  .btn-groups {
    margin: 10px auto;
  }
  .app-header {
    top: 0px;
  }
  .how-it-works.mt-5 {
    margin-top: 120px !important;
  }
}
@media only screen and (min-width: 960px) {
  .videoContainer {
    padding-left: 40px;
  }
}
