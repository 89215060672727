.app-footer {
  background-color: darken($color: $lightgrey, $amount: 5%);
  border-top: none;
  // color: $white;
  &.renter {
    margin-top: 30px;
    // padding: 10px 0px 25px 0;
    background-color: $blue;
    flex: none;
    @media #{$small-up} {
      // padding: 10px 0px 25px 0;
    }
    .nav {
      .nav-item {
        .nav-link {
          color: white;
          font-size: 13px;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }
      }
    }
  }
}

.social-icon {
  width: 40px;
  height: 40px;
  background-color: $white;
  border-radius: 20px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
