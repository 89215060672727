.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.badge-orange {
  background-color: #ff8702;
  color: white;
  font-size: 13px;
}

.badge-orange-tile {
  background-color: #ff8702;
  color: white;
  font-size: 10px;
}

.promo-styling {
  position: absolute;
  top: 10px;
  right: 11px;
  z-index: 1;
}

.Toastify__toast-body {
  z-index: 10222 !important;
}

.ag-theme-balham {
  margin-bottom: 1rem !important;
  height: 22vh;
  min-height: 150px;
}

// .sidebar {
//   z-index: 0 !important;
// }

.navbar-expand-md {
  @media (min-width: 768px) {
    .header-sub-text {
      display: none;
    }
  }

  @media (max-width: 991.98px) {
    .header-sub-text {
      position: absolute;
      top: 41px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    @media (max-width: 375px) {
      .header-sub-text {
        position: absolute;
        top: 41px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 15px;
        white-space: nowrap;
      }
    }
  }
}

.breadcrumb-text {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #ffffff;

  @media (max-width: 375px) {
    font-size: 15px;
    transform: translate(-30%, 0);
  }
}

.secondary-header-styling {
  background: rgba(128, 179, 71, 0.168627);
  padding: 10px 20px;
  color: darken(#edf5e5, 55%);
  font-size: 15px;
  border: 1px solid #8bbc4f82;
  border-bottom: none;
  margin: 0;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff !important;
  background-color: #295081 !important;
  border-color: #29508170;
}
