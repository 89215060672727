.gm-style {
  font-family: "IBM Plex Sans Condensed", sans-serif !important;
  .gm-style-iw-c {
    padding-left: 0;
    padding-top: 0;
    border-radius: 5px;
    max-width: 400px !important;
  }
  .gm-style-iw-d {
    position: relative;
    overflow:auto !important;
  }
  .gm-style-iw-t {
    &::before {
      border-left-width: 6px;
      border-right-width: 6px;
      border-top-width: 6px;
    }
    &::after {
      border-left-width: 5px;
      border-right-width: 5px;
      border-top: 5px solid $black;
    }
  }
  .gm-ui-hover-effect {
    right: 6px !important;
    top: 6px !important;
    font-size: 20px;
    img {
      display: none !important;
    }
    &:after {
      content: "\e082";
      font-family: simple-line-icons;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      color: $white;
    }
  }
}