.switch-3d {
  &.switch-lg {
    &.switch-label {
      width: 56px;
      height: 30px;
      .switch-input {
        &:checked {
          &~.switch-slider {
            &::before {
              transform: translateX(26px);
            }
            &::after {
              font-family: -apple-system, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
            }
          }
        }
      }
    }
  }
}