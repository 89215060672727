.down-arrow {
  position: absolute;
  right: 8px;
  font-size: 22px !important;
  bottom: 17px;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  color: #284f81;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  25% {
    opacity: .25;
  }

  50% {
    opacity: .5;
  }

  75% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}