.chartjs-tooltip {
  background: $white;
  border-radius: 2px;
  border: 1px solid $lightgrey;
  box-shadow: $box-shadow;
  color: $black;
  .tooltip-header {
    text-transform: uppercase;
    color: $darkgrey;
    letter-spacing: 0.1px;
    margin-bottom: 0px;
    .tooltip-header-item {
      font-weight: 500;
    }
  }
  .tooltip-body {
    .tooltip-body-item {
      .tooltip-body-item-color {
        margin-right: 5px;
      }
      .tooltip-body-item-value {
        margin-left:auto;
      }
    }
  }
}