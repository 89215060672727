.map-row {
  @media #{$medium-up} {
    height: calc(100% - 51px);
  }
}

.property-search-form {
  .form-control {
    border-radius: 2px;
    background-color: lighten($color: $blue, $amount: 10%);
    border-color: lighten($color: $blue, $amount: 10%);
    color: lighten($color: $blue, $amount: 50%);
    font-weight: 500;
    &:-moz-placeholder {
      color: lighten($color: $blue, $amount: 50%);
    }
    &::-webkit-input-placeholder {
      color: lighten($color: $blue, $amount: 50%);
    }
    &::-ms-input-placeholder {
      color: lighten($color: $blue, $amount: 50%);
    }
    &:placeholder-shown {
      color: lighten($color: $blue, $amount: 50%);
    }
    &::placeholder {
      color: lighten($color: $blue, $amount: 50%);
    }
  }
  .custom-select {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23e4ecf7' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>");
  }
  .location-field {
    flex: 1 1 auto;
    max-width: 500px;
    .input-group {
      width: 100%;
    }
  }
}

.checkbox-list {
  li {
    padding: 2px 0;
    ul {
      padding-left: 25px;
    }
  }
}

.listing-card {
  &.search-card {
    min-height: 300px;
  }
}

.listing-col {
  padding: 5px 0;
  @media #{$medium-up} and #{$large-down} {
    padding: 5px 5px;
    &:nth-child(even) {
      padding-right: 0;
    }
    &:nth-child(odd) {
      padding-left: 0;
    }
  }
  @media #{$xlarge-up} {
    padding: 5px 5px;
    &:nth-child(3n) {
      padding-right: 0;
    }
    &:nth-child(3n+1) {
      padding-left: 0;
    }
  }
}

.map-col {
  @media #{$small-down} {
    height: 100vh;
    width: 100%;
  }
}

.mobile-view-changer {
  z-index: 999;
  position: fixed;
  width: 170px;
  bottom: 20px;
  left: 20px;
  box-shadow: $box-shadow;
  button {
    padding: 5px;
    text-align: center;
    &.active {
      background-color: $green;
      border-color: $green;
    }
    &:focus, &:active {
      outline: 5px auto rgba(128,179,70, 0.5);
    }
  }
  @media #{$medium-up} {
    display: none;
  }
}