.bg-cover {
  background-position: center center;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

.top-vignette {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.5) 4em, rgba(0,0,0,0) 8em);
}

.bottom-vignette {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 80%);
}

.full-vignette {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.25);
}
