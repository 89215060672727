.btn-groups{
  width:370px;
  display: flex;
  margin-top:40px;
  margin-bottom:40px;
  .btns{
    width:180px;
    height:42px;
    border-radius:21px;
    text-align:center;
    display:flex;
    border:2px solid #284f81;
    justify-content: center;
    align-items: center;
    font-size:18px;
    text-decoration: none !important;
    background-color: transparent;
    color: #284f81;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #162b47;
    }

    &.active {
      background-color:#284f81;
      color:#FFFFFF;
      margin-right:10px;
    }
  }
}

.faq {
  list-style: none;

  .faq-list {
    text-align: left;
    padding-bottom: 1em;
    margin-bottom: 2em;
    border-bottom: 1px dotted #dadada;

    .question-wrapper {
      display: -webkit-inline-box;
      background-color: #f9f9f9;
      border-left: 5px solid #284f81;
      width: 100%;

      .question {
        color: #484848;
        font-size: 20px;
        font-weight: 700;
        display: block;
        padding: 25px 30px 25px 0;
        margin-left: 70px;
        width: calc(100% - 70px);
      }
  
      .question-decor {
        text-align: center;
        font-size: 50px;
        margin-left: 20px;
        color: #284f81;
        position: absolute;
      }
    }

    .answer-wrapper {
      display: -webkit-inline-box;
      border-left: 5px solid #9b9b9b;
      width: 100%;

      .answer {
        color: #8c8c8c;
        font-size: 18px;
        font-weight: 400;
        display: block;
        padding: 15px 30px 15px 0;
        margin-left: 70px;
        width: calc(100% - 70px);
      }
  
      .answer-decor {
        text-align: center;
        font-size: 50px;
        margin-left: 20px;
        color: #8c8c8c;
        position: absolute;
      }
    }
  }
}
