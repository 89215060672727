.list-group {
  .list-group-item {
    border-left: none;
    border-right: none;
    padding-left: 10px;
    padding-right: 10px;
    &:first-child {
      border-radius: 0;
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}