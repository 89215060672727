.subHeaderStyling {
  background: rgba(31, 31, 31, 0.050980392156862744);
  padding: 11px;
  margin: 0px;
  font-size: 15px;
}

.selfEmployedContent {
  margin: 15px;
}

.nestedSubHeading {
  background: rgba(139, 188, 79, 0.19);
  padding: 11px;
  margin: 30px 0px 0px 0px;
  font-size: 15px;
}
