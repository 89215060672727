.onboardHeader{
  padding:10px 0px;
}

.formHeader{
  padding:10px;
  background-color:#80b346;
  color:#FFFFFF;
  p{
    margin:0px;
    color:#FFFFFF !important;
    span{
      display:inline-block;
      border-radius:3px;
      background-color:#597c30;
      padding:2px 8px;
    }
  }
}

.card-header {
  display:flex;
  font-size:20px;
  h3{flex:1 1; display:flex; align-items: center; font-size:20px; margin:0px;}
  .card-header-actions {
    flex:inherit;
    button{background-color:#284f81; color:#FFFFFF !important; padding:8px 14px; border-radius:3px;}
  }
}
.card-footer{
  button{
    padding:6px 14px;
    font-size:16px;
  }
}

.email-verified {
  background-image: url(../../../assets/img/backgrounds/email-verify-bg.jpg);
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;

  .email-verified-content {
    border-radius: 10px;
    color: #FFFFFF;
    background-color: rgba($color: #000000, $alpha: 0.5);
    border: none;
  }

  .email-verified-msg {
    font-size: 20px;
  }

  .email-verified-msg-sub {
    font-size: 16px;
  }

  .login-link {
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 0 10px 0 10px;
    color: #333333;
    font-weight: 600;
  }
}

.due-date {

  .form-group {
    display: inline-block;
    margin: 0 10px;
  }
}