.tab-content {
  border: none;
  padding: 15px 0;
  .tab-pane {
    padding: 0;
  }
}

.nav-tabs {
  border-bottom: none;
  margin-bottom: 1px;
  &~.tab-content {
    padding: 15px;
    border: 1px solid $grey;
    box-shadow: $box-shadow;
    border-top-right-radius: 4px;
  }
  .nav-item {
    .nav-link {
      background: lighten($color: $grey, $amount: 5%);
      border-color: $white;
      color: lighten($color: $black, $amount: 80%);
      font-weight: 500;
      border-bottom-color: $grey;
      transition: background 180ms ease-out;
      &.active {
        background: $white;
        border-color: $grey;
        border-bottom-color: $white;
        color: $black;
      }
    }
  }
}