.font-weight-semibold {
  font-weight: 500 !important;
}

.text-tiny {
  font-size: 10px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-large {
  font-size: 16px !important;
}

.header-large {
  font-size: 50px !important;
}

h1 {
  &.font-size-2x {
    font-size:80px !important;
  }
}

h2 {
  &.font-size-2x {
    font-size: 64px !important;
  }
}

h3 {
  &.font-size-2x {
    font-size: 56px !important;
  }
}

h4 {
  &.font-size-2x {
    font-size: 48px !important;
  }
}

h5 {
  &.font-size-2x {
    font-size: 40px !important;
  }
}

.subhead {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.break-word {
  word-wrap: break-word !important;
}

.graf-text {
  font-size: 15px;
  * {
    font-size: 15px;
  }
}

hr {
  &.thick {
    border-top-width: 8px;
  }
}

.letter-spacing-medium {
  letter-spacing: 0.25px;
}

b, strong {
  font-weight: 700;
}

.or-divider {
  position: relative;
  &:after {
    content: "OR";
    position: absolute;
    top: 0;
    left: 50%;
    width: 50px;
    margin-top: -8px;
    margin-left: -25px;
    text-transform: uppercase;
    background-color: $white;
    text-align: center;
    font-weight: 700;
    color: $black;
    font-size: 16px;
    line-height: 16px;
  }
}