.order {
  text-align: center;

  > .fa {
    float: left;
    vertical-align: middle;
    line-height: 20px;
  }
}

.drop-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.help-icon-styling {
  position: absolute;
  margin: 13px;
  right: 20px;
  color: white;
  &:hover {
    cursor: pointer;
  }
}
