.btn {
  font-weight: 500;
  border-radius: 2px;
  &.btn-success {
    color: $white;
    font-weight: 500;
    &.disabled, &:disabled {
      color: $white;
    }
  }
  &.btn-white {
    color: $darkgrey;
    font-weight: 500;
    background-color: $white;
    opacity: 1;
    &.disabled, &:disabled {
      opacity: 0.8;
    }
  }
  &.btn-outline-white {
    color: $white;
    border: 1px solid $white;
  }
  &.btn-pill {
    border-radius: 50em;
  }
  &.btn-sm {
    line-height: 1.2;
  }
}