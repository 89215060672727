// Variable overrides
$blue: #284f81;
$lightblue: #00aeef;
$green: #80b346;
$orange: #F2973B;
$white: #ffffff;
$black: #000000;
$lightgrey: #f4f3ef;
$grey: #e4e7ea;
$darkgrey: #5c6873;
$danger: #E42B30;
$pink: #F86C6B;

$colors: (
  "blue": $blue,
  "lightblue": $lightblue,
  "green": $green,
  "orange": $orange,
  "white": $white,
  "black": $black,
  "grey": $grey,
  "darkgrey": $darkgrey,
  "lightgrey": $lightgrey,
  "red": $danger,
  "pink": $pink
);

$font-family-sans-serif: 'IBM Plex Sans Condensed', sans-serif;

$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$tiny-up: "(min-width: #{$breakpoint-xs})";
$tiny-down: "(max-width: #{$breakpoint-sm - 1px})";
$small-up: "(min-width: #{$breakpoint-sm})";
$small-down: "(max-width: #{$breakpoint-md - 1px})";
$medium-up: "(min-width: #{$breakpoint-md})";
$medium-down: "(max-width: #{$breakpoint-lg - 1px})";
$large-up: "(min-width: #{$breakpoint-lg})";
$large-down: "(max-width: #{$breakpoint-xl - 1px})";
$xlarge-up: "(min-width: #{$breakpoint-xl})";

$header-height: 88px;
$header-height-mobile: 84px;

$box-shadow: 0 6px 10px -1px rgba(0,0,0,0.15);