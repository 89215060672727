.bgImage {
  position: relative;
  .search-bar {
    .search-input {
      background-color: rgba(255,255,255,0.5);
      padding: 0.5rem;
      border-radius: 0.25rem;
    }
  }
}

.listing-card {
  border: none;
  min-height: 400px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .bottom-vignette {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.listing-card-footer {
  margin-right: 0;
  margin-left: 0;
  .col {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $white;
    border-left: 1px solid darken($color: $lightgrey, $amount: 10%);
    border-right: 1px solid darken($color: $lightgrey, $amount: 10%);
    border-bottom: 1px solid darken($color: $lightgrey, $amount: 10%);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}


.user-types {
  &.card {
    background: none;
    border-radius: 0;
    .card-body {
      padding: 40px 1.25rem 0 1.25rem;
      position: relative;
      i {
        color: $white;
        font-size: 80px;
        margin-bottom: 30px;
      }
      p {
        color: $white;
      }
    }
    .card-footer {
      background: none;
      position: relative;
      border-top: none;
      padding: 40px 1.25rem 40px 1.25rem;
    }
  }
}
