.modal {
  .modal-content {
    border-radius: 4px;
    border: none;
    .modal-header {
      border-bottom: none;
      padding-left: 30px;
      padding-right: 30px;
      button {
        margin: -9px 0px;
      }
      .modal-title {
        font-size: 28px;
      }
    }
    .modal-footer {
      padding-left: 30px;
      padding-right: 30px;
      border-top: none;
    }
    .modal-body {
      padding-left: 30px;
      padding-right: 30px;
      label {
        margin-bottom: 0;
        color: $darkgrey;
        font-size: 12px;
      }
      select {
        max-width: 300px;
      }
      .custom-checkbox {
        .custom-control-label {
          &:before {
            top: 0;
          }
          &:after {
            top: 0;
          }
        }
      }
    }
  }
}
