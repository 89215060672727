.card {
  border: none;
  box-shadow: box-shadow;
  .card-header {
    background-color: #80b346;
    color: white;
    font-weight: 500;
    border: none;
    .cutout {
      padding: 6px;
      // margin: auto;
      background-color: #4c6a29;
      box-shadow: box-shadow;
      border-radius: 4px;
    }
    &.bg-blue {
      background-color: blue;
      .cutout {
        background-color: darken($color: blue, $amount: 15%);
      }
    }
    &.bg-grey {
      background-color: grey;
      .cutout {
        background-color: darken($color: grey, $amount: 15%);
      }
    }
    &.bg-darkgrey {
      background-color: darkgrey;
      .cutout {
        background-color: darken($color: darkgrey, $amount: 15%);
      }
    }
  }
  .card-body {
    border: none;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .captcha-wrap {
    margin: 0px -18px;
  }
}
