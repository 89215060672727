.section {
  padding: 100px 0;
}

.h-100vh {
  height: 100vh;
  @media #{$medium-up} {
    height: 100vh;
  }
}

.w-100vw {
  width: 100vw;
}