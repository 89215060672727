
.login {
  .card-group {
    .card {
      margin-bottom: 0;
      @media #{$medium-up} {
        margin-bottom: 15px;
      }
      &:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        @media #{$medium-up} {
          border-bottom-left-radius: 4px;
        }
      }
      &:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        @media #{$medium-up} {
          border-top-right-radius: 4px;
        }
      }
    }
  }
}