// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.bd-highlight {
    border: 4px dashed $grey;
    cursor: pointer;
    &:active {
        background-color: lighten($color: $grey, $amount: 5%);
        cursor: grabbing;
        outline: none;
        box-shadow: none;
    }
}

/**
 * Auxiliary classes to set "with" boundaries between elements
 */
.ms-minW-39x {
    min-width: 39px !important;
}

.ms-minW-106x {
    min-width: 106px !important;
}

.ms-cursor-pointer {
  cursor: pointer !important;
}
