// renter nav
.app-header {
  background-color: $white;
  height: auto;
  flex: 0 0 auto;

  &.renter {
    background-color: $white;
    border-bottom: 2px solid $green;
    min-height: 6em;

    #header-nav {
      .nav-link {
        // padding: 0 0 10px 0;
        padding: 0px;
      }
    }

    @media #{$small-down} {
      // padding-left: 5.8em;
      & #header-nav {
        padding: 0;

        & .nav-item {
          width: 100%;
          background: $blue;
          padding: 0 !important;

          & .active {
            background: $green;
          }

          .nav-link {
            padding: 8px;
            color: $white;
            border-bottom: none;

            .text-blue {
              color: $white !important;
            }
          }
        }

        .navbar-collapse {
          margin-top: 35px;
        }
      }
    }
    @media #{$medium-up} {
      padding-left: 7.8em;
      padding-right: 3em;
    }
    @media screen and (max-width: 925px) and (min-width: 768px) {
      padding-left: 3.8em;
      padding-right: 0;
    }
    .navbar-toggler {
      margin-top: 1rem;
    }
    .navbar-collapse {
      .navbar-nav {
        // align-items: flex-end !important;
      }
    }
  }
  &.transparent {
    background: none;
    border-bottom: none;
    .navbar-nav {
      .nav-link {
        color: $white;
      }
    }
    .navbar-toggler {
      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
        border: 1px solid $grey;
      }
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='white' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
      }
    }
    .navbar-toggler-icon {
      color: $white;
    }
    .navbar-collapse {
      background: rgba(0, 0, 0, 0.9);
      .navbar-nav {
        .nav-item {
          .nav-link {
            border-bottom: 8px solid transparent;
          }
          &.active {
            .nav-link {
              border-bottom: 8px solid $white;
              border-left: none !important;
            }
          }
        }
      }
    }
    .navbar-expand-md {
      @media (max-width: 991.98px) {
        .header-sub-text {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -7rem;
        }
      }
      .navbar-collapse {
        @media #{$medium-up} {
          background: none;
        }
      }
    }
  }
  .navbar-brand {
    top: 1rem;
  }
  .navbar-collapse {
    margin-top: 20px;
    .navbar-nav {
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      .nav-item {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: left;
        .nav-link {
          padding-bottom: 10px;
          border-bottom: 8px solid $white;
        }
        &.active {
          .nav-link {
            border-left: 4px solid $green;
            padding-left: 10px !important;
          }
        }
      }
    }
  }
  .navbar-expand-md {
    .navbar-brand {
      width: 200px;
      height: auto;
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        margin-left: -2rem !important;
      }
      @media only screen and (max-width: 768px) {
        margin-left: -7rem;
      }
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
        margin-left: -7rem !important;
      }

      @media #{$medium-up} {
        top: auto;
        left: auto;
        position: relative;
        // margin-left: 0;
        margin-left: -6rem;
      }
    }
    .navbar-toggler {
      @media #{$medium-up} {
        margin-top: 0;
      }
    }
    .navbar-collapse {
      @media #{$medium-up} {
        padding-top: 0;
      }
      .navbar-nav {
        @media #{$medium-up} {
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-align-items: center;
          align-items: center;
        }
        .nav-item {
          @media #{$medium-up} {
            // margin-left: 1rem;
            padding-top: 0;
            padding-bottom: 0;
            text-align: left;
          }
        }
      }
    }
  }
}
.navbar {
  -webkit-align-items: flex-start;
  align-items: flex-start;
  @media #{$medium-up} {
    -webkit-align-items: center;
    align-items: center;
  }
}

// pm nav
.breadcrumb {
  background-color: $blue;
  padding: 9px 10px 9px 30px;
  font-size: 18px;
  .breadcrumb-item {
    a {
      color: $white;
    }
    &.active {
      color: $white;
      font-weight: 700;
    }
  }
}

.admin {
  .app-header {
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    .navbar-brand {
      top: 0;
      @media #{$medium-down} {
        position: relative;
        top: auto;
        left: auto;
        margin-left: 0;
      }
    }
    .dropdown-menu {
      background-color: $white;
      border: none;
      border-radius: 0;
      border-bottom-left-radius: 4px;
      box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.15);
      min-width: 250px;
      .dropdown-header {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.1px;
      }
      .dropdown-item {
        color: $black;
        i {
          color: $black;
        }
        &:hover {
          background-color: $blue;
          color: $white;
          i {
            color: $white;
          }
        }
      }
    }
  }
}

.header-fixed {
  .app-body {
    &.renter-admin {
      margin-top: 84px;
    }
  }
}

.sidebar-fixed {
  .renter-admin {
    .app-header {
      & + .app-body {
        .sidebar {
          height: calc(100vh - 96px);
        }
      }
    }
  }
}

.nav-pills {
  .nav-item {
    flex: 1 0 auto;
    .nav-link {
      width: 100%;
      border: 1px solid #005b84;
      border-radius: 0;
      background-color: #284f811f;
      color: #284f81;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      text-transform: uppercase;
      text-align: center;
      &:hover {
        color: $white;
        background-color: lighten($color: $blue, $amount: 10%);
        border: 1px solid lighten($color: $blue, $amount: 10%);
      }
      &.active {
        background-color: $blue;
        color: white !important;
        border: 1px solid $blue;
        &:hover {
          background-color: $blue;
          border: 1px solid $blue;
        }
      }
    }
  }
}

.app-body {
  @media #{$small-down} {
    margin-top: $header-height-mobile;
  }

  @media screen and (max-width: 992px) and (min-width: 768px) {
    margin-top: 92px;
  }
}

.nav {
  &.stepper {
    justify-content: space-between;
    position: relative;
    flex-wrap: nowrap;
    margin: 0 0 50px 0;
    &:before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      top: 50%;
      left: 0;
      background-color: $grey;
    }
    // &:after {
    //   content: "\e079";
    //   font-family: simple-line-icons;
    //   position: absolute;
    //   font-size: 18px;
    //   background-color: $white;
    //   top: 50%;
    //   left: 50%;
    //   margin-top: -13px;
    //   margin-left: -8px;
    //   color: $grey;
    // }
    .nav-item {
      font-size: 16px;
      font-weight: 400;
      background-color: $white;
      z-index: 2;
      position: relative;
      padding: 5px 10px;
      color: $grey;
      .stepper-counter {
        width: 25px;
        height: 25px;
        border-radius: 13px;
        line-height: 23px;
        background-color: $grey;
        border: 1px solid $grey;
        color: lighten($color: $darkgrey, $amount: 30%);
        font-size: 10px;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
        font-weight: 700;
      }
      &.active {
        color: $black;
        .stepper-counter {
          background-color: $green;
          border-color: $green;
          color: $white;
        }
      }
    }
  }
}
