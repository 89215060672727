.due-date {

  .form-group {
    display: inline-block !important;
    margin: 0 10px;
  }
}

td:not(:last-child) {
  min-width: 150px;
}