.onboardHeader {
  padding: 10px 0px;
}

.formHeader {
  padding: 10px;
  background-color: #80b346;
  color: #ffffff;
  p {
    margin: 0px;
    color: #ffffff !important;
    span {
      display: inline-block;
      border-radius: 3px;
      background-color: #597c30;
      padding: 2px 8px;
    }
  }
}

.card-header {
  display: flex;
  font-size: 20px;
  h3 {
    flex: 1 1;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 0px;
  }
  .card-header-actions {
    flex: inherit;
    button {
      background-color: #284f81;
      color: #ffffff !important;
      padding: 8px 14px;
      border-radius: 3px;
    }
  }
}
.card-footer {
  button {
    padding: 6px 14px;
    font-size: 16px;
  }
}

.ratioWrap {
  margin-top: 29px;
  background-color: transparent !important;
  border: none !important;
}

.ratioWrapColon {
  margin-top: 34px;
  font-size: 15px;
}

.rbt-input-multi {
  height: auto !important;
}

.no-rows {
  border-top: 0;
  padding: 5px;
  text-align: center;
  box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}